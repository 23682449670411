import Alpine from 'alpinejs';
import axios from 'axios';
window.Alpine = Alpine;
window.axios = axios;

window.pieChart = ($token) => {
    return {
        token: $token,
        tokenomics: $token.tokenomics,
        labels: [],
        colors: [],
        values: [],

        init() {
            this.labels = this.tokenomics.map((tokenomic)=>{
                return '% '+tokenomic.name
            });

            this.colors = this.tokenomics.map((tokenomic)=>{
                return tokenomic.color
            });

            this.values = this.tokenomics.map((tokenomic)=>{
                return tokenomic.pivot.value/this.token.total*100
            });

            const myChart = new Chart(document.getElementById("myChart"), {
                type: 'pie',
                data: {
                    labels: this.labels,
                    datasets: [{
                        label: "Percentage",
                        backgroundColor: this.colors,
                        hoverBackgroundColor: this.colors,
                        data: this.values
                    }]
                },
                options: {
                    title: {
                        display: true,
                        text: 'Predicted world population (millions) in 2050'
                    }
                }
            });
        }
    }
}

window.tvChart = ($token) => {
    return {
        token: $token,
        tvchartdata: [],
        final: [],
        TokenPrice: [],
        SecondTokenPrice: [],
        FinalData: [],
        formatedDate: '',
        query: '',
        times: 0,
        volume: 0,
        VolumenData: [],

        init() {
            if (!Date.prototype.toISOString) {
                (function() {

                    function pad(number) {
                        if (number < 10) {
                            return '0' + number;
                        }
                        return number;
                    }

                    Date.prototype.toISOString = function() {
                        return this.getUTCFullYear() +
                            '-' + pad(this.getUTCMonth() + 1) +
                            '-' + pad(this.getUTCDate()) +
                            'T' + pad(this.getUTCHours()) +
                            ':' + pad(this.getUTCMinutes()) +
                            ':' + pad(this.getUTCSeconds()) +
                            '.' + (this.getUTCMilliseconds() / 1000).toFixed(3).slice(2, 5) +
                            'Z';
                    };

                }());
            }

            var dateStr = this.token.release_date; //returned from mysql timestamp/datetime field
            var a = dateStr.split(" ");
            var d = a[0].split("-");
            this.formatedDate = new Date(d[0], (d[1] - 1), d[2]);
            this.formatedDate = new Date(this.formatedDate.getTime() - (this.formatedDate.getTimezoneOffset() * 60000)).toISOString();

            if (this.token.intermediate_contract != null) {
                this.query = `{
                    ethereum(network: ` + this.token.blockchain + `) {
                            dexTrades(
                            any: [{baseCurrency: {is: "` + this.token.contract + `"}, quoteCurrency: {is: "` + this.token
                                .intermediate_contract + `"}}, {baseCurrency: {is: "` + this.token.final_contract +
                                `"}, quoteCurrency: {is: "` + this.token.contract + `"}}, {baseCurrency: {is: "` + this
                                .token
                                .intermediate_contract + `"}, quoteCurrency: {is: "` + this.token.final_contract + `"}}]
                            date: {since: "` + this.formatedDate + `"}
                            tradeAmountUsd: {gt: 10}
                            ) {
                            timeInterval {
                                minute(format: "%FT%TZ", count: 30)
                            }
                            buyCurrency: baseCurrency {
                                symbol
                            }
                            sellCurrency: quoteCurrency {
                                symbol
                                #address
                            }
                            tradeAmount(in: USDT)
                            high: quotePrice(calculate: maximum)
                            low: quotePrice(calculate: minimum)
                            open: minimum(of: block, get: quote_price)
                            close: maximum(of: block, get: quote_price)
                            }
                        }
                }`;
            }
            else{
                this.query = `{
                    ethereum(network: ` + this.token.blockchain + `) {
                            dexTrades(
                            buyCurrency: {is: "` + this.token.contract + `"}
                            sellCurrency: {is: "` + this.token.final_contract + `"}
                            date: {since: "` + this.formatedDate + `"}
                            tradeAmountUsd: {gt: 10}
                            ) {
                            timeInterval {
                                minute(format: "%FT%TZ", count: 30)
                            }
                            buyCurrency: baseCurrency {
                                symbol
                            }
                            sellCurrency: quoteCurrency {
                                symbol
                                #address
                            }
                            tradeAmount(in: USDT)
                            high: quotePrice(calculate: maximum)
                            low: quotePrice(calculate: minimum)
                            open: minimum(of: block, get: quote_price)
                            close: maximum(of: block, get: quote_price)
                            }
                        }
                }`;
            }

            const url = "https://graphql.bitquery.io/";
            const opts = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-API-KEY": "BQYbnyEAXHf8WHOsDzFfzUnA3c0ZvptO"
                },
                body: JSON.stringify(this.query),

            };

            window.candleSeries;
            window.chart;
            window.volumeSeries;

            this.fn60sec();

            setInterval(() => {
                this.fn60sec()
            }, 60 * 1000);
        },

        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },

        fn60sec() {
            this.TokenPrice = [];
            this.SecondTokenPrice = [];
            this.FinalData = []
            axios({
                    url: 'https://graphql.bitquery.io/',
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        "Content-Type": "application/json",
                        "X-API-KEY": 'BQYbnyEAXHf8WHOsDzFfzUnA3c0ZvptO'
                    },
                    data: {
                        query: this.query
                    }
                }).then((response) => {
                    this.tvchartdata = response.data
                    this.final = this.tvchartdata["data"]["ethereum"]["dexTrades"];
                    console.log(this.final);

                    for (var i = 0; i < this.final.length; i++) {
                        if (this.final[i]["open"] == this.final[i]["close"] && this.final[i]["open"] == this
                            .final[i]["high"] && this.final[i]["open"] == this.final[i]["low"] && this
                            .final[i]["buyCurrency"]["symbol"].toUpperCase() == this.token.symbol) {
                            } else {
                            if (this.final[i]["buyCurrency"]["symbol"].toUpperCase() == this.token.symbol.toUpperCase()) {
                                this.TokenPrice.push(this.final[i]);
                            } else {
                                if(this.token.intermediate_contract != null){
                                    this.SecondTokenPrice.push(this.final[i]);
                                }
                                else{}
                            }
                        }
                    }

                    if(this.token.intermediate_contract != null){
                        for (var i = 0; i < this.TokenPrice.length; i++) {
                            while (this.TokenPrice[i]["timeInterval"]["minute"] != this.SecondTokenPrice[i][
                                    "timeInterval"
                                ]["minute"]) {
                                this.SecondTokenPrice.splice(i, 1);
                            }
                        }
                    }

                    var date
                    var seconds
                    var a = new Date();
                    var b
                    this.volume = 0;

                    for (var j = 0; j < this.TokenPrice.length; j++) {
                        date = new Date(this.TokenPrice[j]["timeInterval"]["minute"]);
                        seconds = date.getTime() / 1000;
                        const timezoneOffsetMinutes = new Date().getTimezoneOffset();
                        seconds -= timezoneOffsetMinutes * 60;

                        if(this.TokenPrice[j]["open"] < this.TokenPrice[j]["close"]){
                            this.VolumenData[j] = {
                                time: seconds,
                                value: parseFloat(this.TokenPrice[j]["tradeAmount"]),
                                color: 'rgba(16, 185, 129, 0.5)',
                            }
                        }
                        else{
                            this.VolumenData[j] = {
                                time: seconds,
                                value: parseFloat(this.TokenPrice[j]["tradeAmount"]),
                                color: 'rgba(230,43,74, 0.5)',
                            }
                        }

                        var a = new Date();
                        b = new Date(this.TokenPrice[j]["timeInterval"]["minute"]);
                        if(a-b <= 86400000){
                            this.volume = this.volume + this.TokenPrice[j]["tradeAmount"]
                        }

                        this.volume = Math.round( this.volume * 10 ) / 10;

                        if(this.token.intermediate_contract != null){
                            this.FinalData[j] = {
                                time: seconds,
                                open: parseFloat(this.TokenPrice[j]["open"]) * (parseFloat(this
                                    .SecondTokenPrice[j][
                                        "open"
                                    ]) + parseFloat(
                                    this.SecondTokenPrice[j]["close"])) / 2,
                                high: parseFloat(this.TokenPrice[j]["high"]) * (parseFloat(this
                                    .SecondTokenPrice[j][
                                        "open"
                                    ]) + parseFloat(
                                    this.SecondTokenPrice[j]["close"])) / 2,
                                low: parseFloat(this.TokenPrice[j]["low"]) * (parseFloat(this
                                        .SecondTokenPrice[j]["open"]) +
                                    parseFloat(
                                        this.SecondTokenPrice[j]["close"])) / 2,
                                close: parseFloat(this.TokenPrice[j]["close"]) * (parseFloat(this
                                    .SecondTokenPrice[j][
                                        "open"
                                    ]) + parseFloat(
                                    this.SecondTokenPrice[j]["close"])) / 2
                            }
                        }
                        else {
                            this.FinalData[j] = {
                                time: seconds,
                                open: parseFloat(this.TokenPrice[j]["open"]),
                                high: parseFloat(this.TokenPrice[j]["high"]),
                                low: parseFloat(this.TokenPrice[j]["low"]),
                                close: parseFloat(this.TokenPrice[j]["close"])
                            }
                        }
                    }

                    if (this.times == 0) {
                        this.times = 1;
                        window.chart = LightweightCharts.createChart(document.getElementById('chart'), {
                            width: $('.parent-chart').outerWidth(),
                            height: $('.parent-chart').outerHeight(),
                            timeScale: {
                                timeVisible: true,
                                secondsVisible: false,
                            },
                        });

                        window.volumeSeries = window.chart.addHistogramSeries({
                            color: '#26a69a',
                            priceFormat: {
                                type: 'volume',
                            },
                            priceScaleId: 'top',
                            scaleMargins: {
                                top: 0.8,
                                bottom: 0,
                            },
                            autosize: true,
                            priceLineVisible: false,
                        });

                        window.candleSeries = window.chart.addCandlestickSeries({
                            upColor: 'rgba(16, 185, 129, 1)',
                            downColor: 'rgba(230,43,74, 1)',
                            borderDownColor: 'rgba(230,43,74, 1)',
                            borderUpColor: 'rgba(16, 185, 129, 1)',
                            wickDownColor: 'rgba(230,43,74, 1)',
                            wickUpColor: 'rgba(16, 185, 129, 1)',
                        });

                        window.candleSeries.applyOptions({
                            priceFormat: {
                                type: 'price',
                                precision: 5,
                                minMove: 0.00001,
                            },
                            autosize: true
                        });

                        $(window).resize(function() {
                            window.chart.applyOptions({
                                width: $('.parent-chart').outerWidth(),
                                height: $('.parent-chart').outerHeight()
                            });
                        });
                    }

                    console.log('Final data')
                    console.log(this.FinalData);

                    window.candleSeries.setData(this.FinalData);
                    window.volumeSeries.setData(this.VolumenData);
                    console.log('data actualizada');
                })
                .catch(console.error);
        }
    }
}

window.scrollProgress = ($final) => {
    return {
        circumference: 70 * 2 * Math.PI,
        percent: 3,
        final: $final,

        init() {
            var startTime = new Date().getTime();
            var interval = setInterval(() => {
                if (this.percent > this.final) {
                    clearInterval(interval);
                    return;
                }
                this.percent = this.percent + this.final / 100
            }, 5);
        }
    }
}

window.scrollProgressSmall = ($final) => {
    return {
        circumference: 17.4 * 2 * Math.PI,
        percent: 0,
        final: $final,

        init() {
            var startTime = new Date().getTime();
            var interval = setInterval(() => {
                if (this.percent > this.final) {
                    clearInterval(interval);
                    return;
                }
                this.percent = this.percent + this.final / 100
            }, 5);
        }
    }
}

window.TokenList = ($tokens) => {
    return {
        tokens: $tokens,
        search: '',
        launched: 1,
        order: 0,

        filteredTokens() {
            return this.tokens.filter(
                token => token.name.toLowerCase().includes(this.search.toLowerCase()) && token.launched == parseInt(this.launched)
            )
        },

        orderTokens() {
            if(this.order == 0){
                this.order = 1;
                this.tokens.sort((a,b) => (a.release_date > b.release_date) ? 1 : ((b.release_date > a.release_date) ? -1 : 0))
            }
            else if(this.order == 1){
                this.order = 2;
                this.tokens.sort((a,b) => (a.release_date > b.release_date) ? -1 : ((b.release_date > a.release_date) ? 1 : 0))
            }
            else{
                this.order = 1;
                this.tokens.sort((a,b) => (a.release_date > b.release_date) ? 1 : ((b.release_date > a.release_date) ? -1 : 0))
            }
        }
    }
}

window.CategoryList = ($categories) => {
    return {
        categories: $categories,
    }
}

window.WarningList = ($warnings) => {
    return {
        warnings: $warnings,
    }
}

window.TokenomicList = ($tokenomics) => {
    return {
        t: $tokenomics,
        tokenomics: [],
        taxs: [],
        taxs_buy: [],
        taxs_sell: [],

        init(){
            this.tokenomics = this.t.filter(x => x.type == 'tokenomic');
            this.taxs = this.t.filter(x => x.type == 'tax');
            this.taxs_buy = this.t.filter(x => x.type == 'tax_buy');
            this.taxs_sell = this.t.filter(x => x.type == 'tax_sell');
        }
    }
}

window.CFunctionList = ($c_functions) => {
    return {
        c_functions: $c_functions,
    }
}

window.MemberList = ($members) => {
    return {
        members: $members,
        search: '',

        filteredMembers() {
            return this.members.filter(
                member => member.name.toLowerCase().includes(this.search.toLowerCase())
            );
        },
    }
}

window.RoadmapList = ($roadmaps) => {
    return {
        roadmaps: $roadmaps,
    }
}

Alpine.start();

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
